import {
  GET_ALL_JOBS_FAILURE,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_SUCCESS_ONE_TIME,
  SELECTED_JOB,
  SELECTED_JOB_RESET,
} from "../constant/Career";

export function CareerReducer(state = {}, action) {
  switch (action.type) {
    case GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        loading: true,
        getAllJobsData: action.payload,
      };
      case GET_ALL_JOBS_SUCCESS_ONE_TIME:
      return {
        ...state,
        loading: true,
        getAllJobsDataOneTime: action.payload,
      };

    case SELECTED_JOB:
      return {
        ...state,
        loading: true,
        selectedJob: action.payload,
      };

    case SELECTED_JOB_RESET:
      return {
        ...state,
        loading: false,
        selectedJob: {},
      };

    case GET_ALL_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
