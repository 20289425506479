import {
  GET_ALL_SERVICES_FAILURE,
  GET_ALL_SERVICES_REQUEST,
  GET_ALL_SERVICES_SUCCESS,
} from "../constant/Services";

export function ServicesPage(state = {}, action) {
  switch (action.type) {
    case GET_ALL_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        allServices: action.payload,
      };
    case GET_ALL_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
