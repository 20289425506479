import {
  GET_ALL_ADDRESS_TYPES_FAILURE,
  GET_ALL_ADDRESS_TYPES_REQUEST,
  GET_ALL_ADDRESS_TYPES_SUCCESS,
  GET_ALL_CUSTOMER_REQ_DATA_FAILURE,
  GET_ALL_CUSTOMER_REQ_DATA_REQUEST,
  GET_ALL_CUSTOMER_REQ_DATA_SUCCESS,
  GET_ALL_PREV_DISPATCHERS_FAILURE,
  GET_ALL_PREV_DISPATCHERS_REQUEST,
  GET_ALL_PREV_DISPATCHERS_SUCCESS,
  GET_CUSTOMER_ADDRESS_LIST_FAILURE,
  GET_CUSTOMER_ADDRESS_LIST_REQUEST,
  GET_CUSTOMER_ADDRESS_LIST_SUCCESS,
  SET_IS_OPEN_END_ADDRESS,
  SET_REQUEST_FORM_VISIBLE,
  SET_SELECT_ADDRESS_VISIBLE,
  STORE_SELECTED_END_ADDRESS,
  STORE_SELECTED_START_ADDRESS,
} from "../constant/CustomerQuoForm";

export function CustomerQuoFormReducer(state = {}, action) {
  switch (action.type) {
    case GET_CUSTOMER_ADDRESS_LIST_REQUEST:
    case GET_ALL_CUSTOMER_REQ_DATA_REQUEST:
    case GET_ALL_ADDRESS_TYPES_REQUEST:
    case GET_ALL_PREV_DISPATCHERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_IS_OPEN_END_ADDRESS:
      return {
        ...state,
        loading: false,
        isOpenEndAddr: action.payload || false,
      };
    case SET_REQUEST_FORM_VISIBLE:
      return {
        ...state,
        loading: false,
        setCustomerVisible: action.payload || false,
      };
    case SET_SELECT_ADDRESS_VISIBLE:
      return {
        ...state,
        loading: false,
        setSelectAddressVisible: action.payload || false,
      };

    case GET_ALL_CUSTOMER_REQ_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllCustomerReqListData: action.payload,
      };

    case STORE_SELECTED_START_ADDRESS:
      return {
        ...state,
        loading: false,
        selectedStartAddrData: action.payload,
      };

    case STORE_SELECTED_END_ADDRESS:
      return {
        ...state,
        loading: false,
        selectedEndAddrData: action.payload,
      };
    case GET_CUSTOMER_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCustomerAddrListData: action.payload,
      };

    case GET_ALL_ADDRESS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllAddressTypes: action.payload,
      };
    case GET_ALL_PREV_DISPATCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllPrevDispatchers: action.payload,
      };
    case GET_CUSTOMER_ADDRESS_LIST_FAILURE:
    case GET_ALL_CUSTOMER_REQ_DATA_FAILURE:
    case GET_ALL_ADDRESS_TYPES_FAILURE:
    case GET_ALL_PREV_DISPATCHERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
