import {
  GET_WALLET_HISTORY_AND_AMOUNT_FAILURE,
  GET_WALLET_HISTORY_AND_AMOUNT_REQRUEST,
  GET_WALLET_HISTORY_AND_AMOUNT_SUCCESS,
} from "../constant/wallet";

export function WalletReducer(state = {}, action) {
  switch (action.type) {
    case GET_WALLET_HISTORY_AND_AMOUNT_REQRUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WALLET_HISTORY_AND_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getPaymentHistory: action.payload,
      };
    case GET_WALLET_HISTORY_AND_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
