// const initialState = {};

import {
  GET_ALL_DRIVER_LIST_FAILURE,
  GET_ALL_DRIVER_LIST_REQUEST,
  GET_ALL_DRIVER_LIST_SUCCESS,
  GET_ALL_DRIVER_VEHICLE_WISE_FAILURE,
  GET_ALL_DRIVER_VEHICLE_WISE_REQEUST,
  GET_ALL_DRIVER_VEHICLE_WISE_SUCCESS,
  GET_ALL_REQUEST_LIST_FAILURE,
  GET_ALL_REQUEST_LIST_REUEST,
  GET_ALL_REQUEST_LIST_SUCCESS,
  GET_DISPATCHER_QUO_LIST_FAILURE,
  GET_DISPATCHER_QUO_LIST_REQUEST,
  GET_DISPATCHER_QUO_LIST_SUCCESS,
  SET_DIRIVER_DETAILS,
  STORE_CUSTOMER_REQ_ID,
} from "../constant/Dispatcher";

export function Dispatcher(state = {}, action) {
  switch (action.type) {
    case GET_ALL_REQUEST_LIST_REUEST:
    case GET_DISPATCHER_QUO_LIST_REQUEST:
    case GET_ALL_DRIVER_LIST_REQUEST:
    case GET_ALL_DRIVER_VEHICLE_WISE_REQEUST:
      return {
        ...state,
        loading: true,
      };

    case GET_DISPATCHER_QUO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatcherQuoReqData: action.payload,
      };

    case GET_ALL_DRIVER_VEHICLE_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllDriverVehicleWiseData: action.payload,
      };
    case STORE_CUSTOMER_REQ_ID:
      return {
        ...state,
        loading: false,
        customerReqId: action.payload,
      };

    case SET_DIRIVER_DETAILS:
      return {
        ...state,
        loading: false,
        getDriverDetail: action.payload,
      };
    case GET_ALL_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllReqData: action.payload,
      };
    case GET_ALL_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllDriverData: action.payload,
      };
    case GET_ALL_REQUEST_LIST_FAILURE:
    case GET_DISPATCHER_QUO_LIST_FAILURE:
    case GET_ALL_DRIVER_LIST_FAILURE:
    case GET_ALL_DRIVER_VEHICLE_WISE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
