import { createStore, applyMiddleware, combineReducers } from "redux";
// import thunk from "redux-thunk";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { LanguageType } from "./LangTypeReducer";
import { HomePage } from "./HomeReducer";
import { ServicesPage } from "./ServicesReducer";
import { User } from "./UserReducer";
import { CustomerQuoFormReducer } from "./CustomerQuoFormReducer";
import { Dispatcher } from "./Dispatcher";
import { CareerReducer } from "./CareerReducer";
import { WalletReducer } from "./WalletReducer";
import { authReducer } from "./authReducer";

const appReducer = combineReducers({
  lang: LanguageType,
  home: HomePage,
  services: ServicesPage,
  user: User,
  customerQuoForm: CustomerQuoFormReducer,
  dispatcher: Dispatcher,
  jobs: CareerReducer,
  wallet: WalletReducer,
  auth : authReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const middlewareList = [thunk];
const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

export const store = createStore(rootReducer, middleware);
