export const HOME_ROUTE = "/";
export const SERVICES_ROUTE = "/services";
export const RESERVATIONS_ROUTE = "/reservations";
export const CAREERS_ROUTE = "/careers";
export const ABOUT_ROUTE = "/about";
export const CONTACT_US_ROUTE = "/contact-us";
export const PAY_NOW_ROUTE = "/pay-now";
export const PAY_SUCCESS_ROUTE = "/pay-success";
export const PAY_FAILURE_ROUTE = "/pay-fail";
export const LOGIN = "/login";
export const SIGN_UP = "/sign-up";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const RESET_AUTH_PASSWORD = "/reset-password/:authcode";
export const CUSTOMER_DASHBOARD = "/customer-home";
export const REQUEST_QUOTATION_ROUTE = "/request-group-quotation";
export const DISPATCHER_DASHBOARD = "/dispatcher-home";
export const CREATE_TRANSPORT_REQUEST_ROUTE = "/create-transport-request";
export const CUSTOMER_QUO_DETAIL_ROUTE = "/customer-quotation-details";
export const ALL_REQUEST_ROUTE = "/all-request";
export const CUSTOMER_CHAT_ROUTE = "/customer-chat";
export const MY_PROFILE_ROUTE_CUSTOMER = "/customer-profile";
export const BOOKINGS_LOG_ROUTE = "/bookings-log";
export const INVOICING_ROUTE = "/invoicing";
export const CHANGE_PASSWORD_ROUTE = "/change-password";
export const DISPATCHER_CHANGE_PASSWORD_ROUTE = "/dispatcher-change-password";
export const ALL_DRIVERS_ROUTE = "/all-drivers";
export const MY_PROFILE_ROUTE_DISPATCHER = "/dispatcher-profile";
export const DISPATCHER_CHAT_ROUTE = "/dispatcher-chat";
export const BOOKINGS_LOG_ROUTE_ALL_TABS = "/bookings-log-all-tabs";
export const BOOKINGS_LOG_FILTER_ROUTE = "/bookings-log-filter-dd";
export const REPORT_ROUTE = "/report";
export const WALLET_ROUTE = "/wallet";
export const NOTIFICATION_ROUTE = "/notification";
export const DISPATCHER_NOTIFICATION_ROUTE = "/dispatcher-notification";
export const VEHICLE_CHECKOFF_LISTING = "/vehicle-checkoff-list";
export const CHECKOFF_DETAILS = "/checkoff-details";
export const NOTES = "/notes";
export const NOTE_DETAILS = "/note-details";
