import { FIREBASE_TOKEN } from "../constant/auth";

export function authReducer(state = {}, action) {
  switch (action.type) {
    // case GET_ALL_SUB_CATEGORY_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case FIREBASE_TOKEN:
      return {
        ...state,
        loading: false,
        firebaseAuthToken: action.payload,
      };
    // case GET_ALL_SUB_CATEGORY_DATA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    default:
      return state;
  }
}
