import {
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQEUST,
  GET_USER_PROFILE_SUCCESS,
} from "../constant/user";

export function User(state = {}, action) {
  switch (action.type) {
    case GET_USER_PROFILE_REQEUST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfileData: action.payload,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
