export const GET_ALL_REQUEST_LIST_REUEST = "GET_ALL_REQUEST_LIST_REUEST";
export const GET_ALL_REQUEST_LIST_SUCCESS = "GET_ALL_REQUEST_LIST_SUCCESS";
export const GET_ALL_REQUEST_LIST_FAILURE = "GET_ALL_REQUEST_LIST_FAILURE";

export const GET_DISPATCHER_QUO_LIST_REQUEST =
  "GET_DISPATCHER_QUO_LIST_REQUEST";
export const GET_DISPATCHER_QUO_LIST_SUCCESS =
  "GET_DISPATCHER_QUO_LIST_SUCCESS";
export const GET_DISPATCHER_QUO_LIST_FAILURE =
  "GET_DISPATCHER_QUO_LIST_FAILURE";

export const STORE_CUSTOMER_REQ_ID = "STORE_CUSTOMER_REQ_ID";

export const SET_SELECTED_REQUEST_VISIBLE = "SET_SELECTED_REQUEST_VISIBLE";

export const GET_ALL_DRIVER_LIST_REQUEST = "GET_ALL_DRIVER_LIST_REQUEST";
export const GET_ALL_DRIVER_LIST_SUCCESS = "GET_ALL_DRIVER_LIST_SUCCESS";
export const GET_ALL_DRIVER_LIST_FAILURE = "GET_ALL_DRIVER_LIST_FAILURE";

export const SET_DIRIVER_DETAILS = "SET_DIRIVER_DETAILS"

export const GET_ALL_DRIVER_VEHICLE_WISE_REQEUST = "GET_ALL_DRIVER_VEHICLE_WISE_REQEUST"
export const GET_ALL_DRIVER_VEHICLE_WISE_SUCCESS = "GET_ALL_DRIVER_VEHICLE_WISE_SUCCESS"
export const GET_ALL_DRIVER_VEHICLE_WISE_FAILURE = "GET_ALL_DRIVER_VEHICLE_WISE_FAILURE"
