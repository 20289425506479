export const GET_WHY_CHOOSE_US_REQUEST = "GET_WHY_CHOOSE_US_REQUEST";
export const GET_WHY_CHOOSE_US_SUCCESS = "GET_WHY_CHOOSE_US_SUCCESS";
export const GET_WHY_CHOOSE_US_FAILURE = "GET_WHY_CHOOSE_US_FAILURE";

export const GET_CLIENTS_REQUEST = "GET_CLIENTS_REQUEST";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

export const GET_ALL_TESTIMONIALS_REQUEST = "GET_ALL_TESTIMONIALS_REQUEST";
export const GET_ALL_TESTIMONIALS_SUCCESS = "GET_ALL_TESTIMONIALS_SUCCESS";
export const GET_ALL_TESTIMONIALS_FAILURE = "GET_ALL_TESTIMONIALS_FAILURE";

export const GET_SETTING_CONTENT_DATA_REQUEST = "GET_SETTING_CONTENT_DATA_REQUEST";
export const GET_SETTING_CONTENT_DATA_SUCCESS = "GET_SETTING_CONTENT_DATA_SUCCESS";
export const GET_SETTING_CONTENT_DATA_FAILURE = "GET_SETTING_CONTENT_DATA_FAILURE";