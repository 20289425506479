import {
  GET_ALL_TESTIMONIALS_FAILURE,
  GET_ALL_TESTIMONIALS_REQUEST,
  GET_ALL_TESTIMONIALS_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_SETTING_CONTENT_DATA_FAILURE,
  GET_SETTING_CONTENT_DATA_REQUEST,
  GET_SETTING_CONTENT_DATA_SUCCESS,
  GET_WHY_CHOOSE_US_FAILURE,
  GET_WHY_CHOOSE_US_REQUEST,
  GET_WHY_CHOOSE_US_SUCCESS,
} from "../constant/Home";

export function HomePage(state = {}, action) {
  switch (action.type) {
    case GET_WHY_CHOOSE_US_REQUEST:
    case GET_CLIENTS_REQUEST:
    case GET_ALL_TESTIMONIALS_REQUEST:
    case GET_SETTING_CONTENT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WHY_CHOOSE_US_SUCCESS:
      return {
        ...state,
        loading: false,
        whyChooseUs: action.payload,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allClients: action.payload,
      };
    case GET_ALL_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTestimonials: action.payload,
      };
    case GET_SETTING_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        settingContentData: action.payload,
      };
    case GET_WHY_CHOOSE_US_FAILURE:
    case GET_CLIENTS_FAILURE:
    case GET_ALL_TESTIMONIALS_FAILURE:
    case GET_SETTING_CONTENT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
