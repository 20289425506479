import React from "react";

function Loader() {
  return (
    <div className="loader">
      <img
        className="login-logo cursor"
        src="/assets/images/logo.png"
        alt="Logo"
      />
      {/* <div className="spinner-border " role="status">
        <span className="sr-only"></span>
      </div> */}
    </div>
  );
}

export default Loader;
