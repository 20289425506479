export const SET_REQUEST_FORM_VISIBLE = "SET_REQUEST_FORM_VISIBLE";
export const SET_SELECT_ADDRESS_VISIBLE = "SET_SELECT_ADDRESS_VISIBLE";
export const SET_IS_OPEN_END_ADDRESS = "SET_IS_OPEN_END_ADDRESS";
export const STORE_SELECTED_START_ADDRESS = "STORE_SELECTED_START_ADDRESS";
export const STORE_SELECTED_END_ADDRESS = "STORE_SELECTED_END_ADDRESS";

export const GET_CUSTOMER_ADDRESS_LIST_REQUEST =
  "GET_CUSTOMER_ADDRESS_LIST_REQUEST";
export const GET_CUSTOMER_ADDRESS_LIST_SUCCESS =
  "GET_CUSTOMER_ADDRESS_LIST_SUCCESS";
export const GET_CUSTOMER_ADDRESS_LIST_FAILURE =
  "GET_CUSTOMER_ADDRESS_LIST_FAILURE";

export const GET_ALL_CUSTOMER_REQ_DATA_REQUEST =
  "GET_ALL_CUSTOMER_REQ_DATA_REQUEST";
export const GET_ALL_CUSTOMER_REQ_DATA_SUCCESS =
  "GET_ALL_CUSTOMER_REQ_DATA_SUCCESS";
export const GET_ALL_CUSTOMER_REQ_DATA_FAILURE =
  "GET_ALL_CUSTOMER_REQ_DATA_FAILURE";

export const GET_ALL_ADDRESS_TYPES_REQUEST = "GET_ALL_ADDRESS_TYPES_REQUEST";
export const GET_ALL_ADDRESS_TYPES_SUCCESS = "GET_ALL_ADDRESS_TYPES_SUCCESS";
export const GET_ALL_ADDRESS_TYPES_FAILURE = "GET_ALL_ADDRESS_TYPES_FAILURE";

export const GET_ALL_PREV_DISPATCHERS_REQUEST = "GET_ALL_PREV_DISPATCHERS_REQUEST";
export const GET_ALL_PREV_DISPATCHERS_SUCCESS = "GET_ALL_PREV_DISPATCHERS_SUCCESS";
export const GET_ALL_PREV_DISPATCHERS_FAILURE = "GET_ALL_PREV_DISPATCHERS_FAILURE";
