import React, { Suspense } from "react";
import i18n from "i18next";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
  // useNavigate,
} from "react-router-dom";
import {
  HOME_ROUTE,
  ABOUT_ROUTE,
  CAREERS_ROUTE,
  CONTACT_US_ROUTE,
  PAY_NOW_ROUTE,
  RESERVATIONS_ROUTE,
  SERVICES_ROUTE,
  PAY_SUCCESS_ROUTE,
  PAY_FAILURE_ROUTE,
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CUSTOMER_DASHBOARD,
  REQUEST_QUOTATION_ROUTE,
  DISPATCHER_DASHBOARD,
  CREATE_TRANSPORT_REQUEST_ROUTE,
  CUSTOMER_QUO_DETAIL_ROUTE,
  ALL_REQUEST_ROUTE,
  CUSTOMER_CHAT_ROUTE,
  MY_PROFILE_ROUTE_CUSTOMER,
  BOOKINGS_LOG_ROUTE,
  INVOICING_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  ALL_DRIVERS_ROUTE,
  MY_PROFILE_ROUTE_DISPATCHER,
  RESET_AUTH_PASSWORD,
  DISPATCHER_CHANGE_PASSWORD_ROUTE,
  DISPATCHER_CHAT_ROUTE,
  REPORT_ROUTE,
  WALLET_ROUTE,
  NOTIFICATION_ROUTE,
  DISPATCHER_NOTIFICATION_ROUTE,
  VEHICLE_CHECKOFF_LISTING,
  CHECKOFF_DETAILS,
  NOTES,
  NOTE_DETAILS
} from "./routes/RouteEndPoints";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getToken } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/messaging";
import { getMessaging, onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { SetFirebaseAuthToken } from "./store/action/authAction";
import Loader from "./components/Loader/Loader";
// import i18n from "i18next";
const Home = React.lazy(() => import("./pages/General/Home"));
const CustomerNotification = React.lazy(() => import("./pages/Customer/Notification"));
const DispatcherNotification = React.lazy(() => import("./pages/Dispatcher/Notification"));
const Services = React.lazy(() => import("./pages/General/Services"));
const Reservations = React.lazy(() => import("./pages/General/Reservations"));
const Careers = React.lazy(() => import("./pages/General/Careers"));
const About = React.lazy(() => import("./pages/General/About"));
const ContactUs = React.lazy(() => import("./pages/General/ContactUs"));
const PayNow = React.lazy(() => import("./pages/General/PayNow"));
const PaySuccess = React.lazy(() => import("./pages/General/PaySuccess"));
const PayFailure = React.lazy(() => import("./pages/General/PayFailure"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
const SignUp = React.lazy(() => import("./pages/Auth/SignUp"));
const ForgotPassword = React.lazy(() =>
  import("./pages/Auth/ForgotPasswordPage")
);
const ResetPassword = React.lazy(() => import("./pages/Auth/ResetPassword"));
const CustomerHome = React.lazy(() => import("./pages/Customer/CustomerHome"));
const CustomerReqQuo = React.lazy(() =>
  import("./pages/Customer/CustomerReqQuo")
);
const DispatcherHome = React.lazy(() =>
  import("./pages/Dispatcher/DispatcherHome")
);
const CustomerReqForm = React.lazy(() =>
  import("./pages/Customer/CustomerReqForm")
);
const QuotationDetails = React.lazy(() =>
  import("./pages/Customer/QuotationDetails")
);

const Allrequest = React.lazy(() => import("./pages/Customer/Allrequest"));

const CustomerChat = React.lazy(() =>
  import("./pages/Customer/CustomerChatview")
);

const MyProfileCustomer = React.lazy(() =>
  import("./pages/Customer/MyProfile")
);

const BookingsLog = React.lazy(() => import("./pages/Dispatcher/BookingsLog"));

const Invoicing = React.lazy(() => import("./pages/Dispatcher/Invoicing"));

const ChangePassword = React.lazy(() =>
  import("./pages/Customer/ChangePassword")
);

const Wallet = React.lazy(() => import("./pages/Customer/Wallet"));

const AllDrivers = React.lazy(() => import("./pages/Dispatcher/AllDrivers"));
const DispatcherChangePassword = React.lazy(() =>
  import("./pages/Dispatcher/DispatcherChangePassword")
);

const MyProfileDispatcher = React.lazy(() =>
  import("./pages/Dispatcher/MyProfile")
);

const Report = React.lazy(() => import("./pages/Dispatcher/Report"));

const DispatcherChat = React.lazy(() => import("./pages/Dispatcher/ChatView"));
const VehicleCheckoff = React.lazy(() => import("./pages/Dispatcher/VehicleCheckoff"));

const CheckoffDetails = React.lazy(() => import("./pages/Dispatcher/CheckoffDetails"));
const Notes = React.lazy(() => import("./pages/Dispatcher/Notes"));
const NoteDetails = React.lazy(() => import("./pages/Dispatcher/NoteDetails"));

function App() {
  const dispatch = useDispatch();
  const { firebaseAuthToken } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (sessionStorage.getItem("langType")) {
      i18n.changeLanguage(sessionStorage.getItem("langType") || "en");
    }
  }, []);

  React.useEffect(() => {
    try {
      if (!firebaseAuthToken) {
        const firebaseConfig = {
          apiKey: "AIzaSyAynNfb_VE8HilAuDoAnTs4fS8GVF48tdY",
          authDomain: "knight-rider-transportation.firebaseapp.com",
          projectId: "knight-rider-transportation",
          storageBucket: "knight-rider-transportation.appspot.com",
          messagingSenderId: "731976386913",
          appId: "1:731976386913:web:043cfb98c346f73b905d20",
          measurementId: "G-WJLRK71G7P",
        };
        const app = firebase.initializeApp(firebaseConfig);
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const messaging = getMessaging(app);
            getToken(messaging, {
              vapidKey:
                "BOAxXdr23Hn2tXYYhQw22ceOfi0QaaqwUOzdyLigMPCSa61fqh5yrdBEE0DTdvf0HfzKLyHrPrtbvu68tZly-Qs",
            }).then((currentToken) => {
              if (currentToken) {
                dispatch(SetFirebaseAuthToken(currentToken));
                // console.log("firebaseAuthToken: ", currentToken);
              } else {
                console.log("Can not get token");
              }
            });
            // }
          } else {
            console.log("Do not have permission!");
          }
        });
      }
    } catch (error) {
      console.log("error in firebase", error);
    }
  }, [dispatch, firebaseAuthToken]);

  React.useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      if (Notification.permission === "granted") {
        // You can show notifications directly since permission is already granted.
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: "icon.png", // Path to an icon image for the notification.
        });
      } else if (Notification.permission !== "denied") {
        // Request permission from the user.
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(payload.notification.title, {
              title: payload.notification.title,
              body: payload.notification.body,
              icon: "icon.png", // Path to an icon image for the notification.
            });
          }
        });
      }
    });
  }, []);

  window.addEventListener("beforeunload", () => {
    sessionStorage.setItem("oldPathname", window.location.pathname)
  });
  return (
    <Suspense fallback={<Loader />}>
      <ConfirmDialog />
      <Router>
        <Routes>
          <Route exact path={HOME_ROUTE} element={<Home />} />
          <Route exact path={`/:lang${HOME_ROUTE}`} element={<Home />} />
          <Route
            exact
            path={`/:lang${SERVICES_ROUTE}`}
            element={<Services />}
          />
          <Route
            exact
            path={`/:lang${RESERVATIONS_ROUTE}`}
            element={<Reservations />}
          />
          <Route exact path={`/:lang${CAREERS_ROUTE}`} element={<Careers />} />
          <Route exact path={`/:lang${ABOUT_ROUTE}`} element={<About />} />
          <Route
            exact
            path={`/:lang${CONTACT_US_ROUTE}`}
            element={<ContactUs />}
          />
          <Route exact path={`/:lang${PAY_NOW_ROUTE}`} element={<PayNow />} />
          <Route
            exact
            path={`/:lang${PAY_SUCCESS_ROUTE}`}
            element={<PaySuccess />}
          />
          <Route
            exact
            path={`/:lang${PAY_FAILURE_ROUTE}`}
            element={<PayFailure />}
          />
          <Route exact path={`/:lang${LOGIN}`} element={<Login />} />
          <Route exact path={`/:lang${SIGN_UP}`} element={<SignUp />} />
          <Route
            exact
            path={`/:lang${RESET_PASSWORD}`}
            element={<ResetPassword />}
          />
          <Route
            exact
            path={`/:lang${RESET_AUTH_PASSWORD}`}
            element={<ResetPassword />}
          />
          <Route
            exact
            path={`/:lang${CUSTOMER_DASHBOARD}`}
            element={<CustomerHome />}
          />
          <Route
            exact
            path={`/:lang${REQUEST_QUOTATION_ROUTE}`}
            element={<CustomerReqQuo />}
          />
          <Route
            exact
            path={`/:lang${DISPATCHER_DASHBOARD}`}
            element={<DispatcherHome />}
          />
          <Route
            exact
            path={`/:lang${CREATE_TRANSPORT_REQUEST_ROUTE}`}
            element={<CustomerReqForm />}
          />
          <Route
            exact
            path={`/:lang${CUSTOMER_QUO_DETAIL_ROUTE}`}
            element={<QuotationDetails />}
          />
          <Route
            exact
            path={`/:lang${FORGOT_PASSWORD}`}
            element={<ForgotPassword />}
          />
          <Route
            exact
            path={`/:lang${ALL_REQUEST_ROUTE}`}
            element={<Allrequest />}
          />
          <Route
            exact
            path={`/:lang${CUSTOMER_CHAT_ROUTE}`}
            element={<CustomerChat />}
          />
          <Route
            exact
            path={`/:lang${MY_PROFILE_ROUTE_CUSTOMER}`}
            element={<MyProfileCustomer />}
          />
          <Route
            exact
            path={`/:lang${BOOKINGS_LOG_ROUTE}`}
            element={<BookingsLog />}
          />
          <Route
            exact
            path={`/:lang${CHANGE_PASSWORD_ROUTE}`}
            element={<ChangePassword />}
          />
          <Route
            exact
            path={`/:lang${DISPATCHER_CHANGE_PASSWORD_ROUTE}`}
            element={<DispatcherChangePassword />}
          />
          <Route
            exact
            path={`/:lang${INVOICING_ROUTE}`}
            element={<Invoicing />}
          />
          <Route
            exact
            path={`/:lang${ALL_DRIVERS_ROUTE}`}
            element={<AllDrivers />}
          />
          <Route
            exact
            path={`/:lang${MY_PROFILE_ROUTE_DISPATCHER}`}
            element={<MyProfileDispatcher />}
          />
          <Route
            exact
            path={`/:lang${DISPATCHER_CHAT_ROUTE}`}
            element={<DispatcherChat />}
          />
          <Route exact path={`/:lang${REPORT_ROUTE}`} element={<Report />} />
          <Route exact path={`/:lang${WALLET_ROUTE}`} element={<Wallet />} />
          <Route exact path={`/:lang${NOTIFICATION_ROUTE}`} element={<CustomerNotification />} />
          <Route exact path={`/:lang${DISPATCHER_NOTIFICATION_ROUTE}`} element={<DispatcherNotification />} />
          <Route exact path={`/:lang${VEHICLE_CHECKOFF_LISTING}`} element={<VehicleCheckoff />} />
          <Route exact path={`/:lang${CHECKOFF_DETAILS}`} element={<CheckoffDetails />} />
          <Route exact path={`/:lang${NOTES}`} element={<Notes />} />
          <Route exact path={`/:lang${NOTE_DETAILS}`} element={<NoteDetails />} />
          <Route path="*" element={<Navigate to={`${sessionStorage.getItem("oldPathname") || "/"}`} replace />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
